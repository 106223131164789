<template>
  <div class="my-10 bg-white">
    <div class="relative w-full h-full" ref="areaChartParent">
      <div class="relative w-full chart-container" ref="areaChart">
        <line-chart
          :chart-data="chartData"
          :height="chartHeight"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BatteryChartBlock',
  props: {
    data: {
      type: Array,
    },
    tip: {
      type: String,
    },
    labels: {
      type: Array,
    },
    limit: {
      type: Number,
      default: 5,
    },
    minChartVal: {
      type: Number,
      default: 0,
    },
    maxChartVal: {
      type: Number,
      default: 100,
    },
  },
  components: {
    LineChart: () => import('@/components/analytics/charts/LineChart'),
  },
  data() {
    return {
      chartData: {},
      chartHeight: 360,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              bounds: 'ticks',
              ticks: {
                source: 'data',
              },
              time: {
                unit: 'hour',
                stepSize: 1,
                minUnit: 'hour',
                tooltipFormat: 'hh:mm:ss a, DD MMM YYYY',
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              ticks: {
                source: 'data',
                beginAtZero: true,
                stacked: true,
                min: this.minChartVal,
                max: this.maxChartVal,
                // stepSize: 10,
                maxTicksLimit: 20,
                callback: (value) => {
                  return `${value}%`
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 1,
          },
        },
        tooltips: {
          enabled: true,
        },
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  watch: {
    tip(val) {
      window.theTip = val
      console.log(window.theTip)
    },
  },
  mounted() {
    this.fillData(true)
    this.setWidth()
    this.setHeight()
    window.addEventListener('resize', () => {
      this.setHeight()
      this.setWidth()
    })
  },
  methods: {
    fillData() {
      const _data = this.data
      this.chartData = {
        // labels: this.getDates("am", 12),
        datasets: [
          {
            data: _data,
            label: 'Battery',
            // steppedLine: "after",
            fill: true,
            lineTension: 0.6,
            backgroundColor: '#18CB4F',
            borderColor: '#19b2fb',
            borderCapStyle: 'butt',
            borderDash: [],
            borderWidth: 2,
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ffffff',
            pointBackgroundColor: '#19b2fb',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: '#19b2fb',
            pointHoverBorderColor: '#ffffff',
            pointHoverBorderWidth: 3,
            pointRadius: [0, 0, 0, 0, 0, 0, 8],
            pointHitRadius: 10,
          },
        ],
      }
    },
    getDates(month = 'Oct', totalDays = 31, startingDate = 1) {
      let result = []
      while (startingDate <= totalDays) {
        result.push(`${startingDate} ${month}`)
        startingDate++
      }
      return result
    },
    setWidth() {
      if (this.$refs['areaChart'] && this.$refs['areaChart'].clientWidth) {
        this.chartWidth = this.$refs['areaChart'].clientWidth
      }
    },
    setHeight() {
      if (
        this.$refs['areaChartParent'] &&
        this.$refs['areaChartParent'].clientHeight
      ) {
        this.chartHeight = this.$refs['areaChartParent'].clientHeight
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.chart-container {
  display: block;
  // height: 350px !important;
  width: 100% !important;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 4px;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}
</style>
